@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* .Toastify__toast-theme--colored.Toastify__toast--success {
    background: #54c320;
} */
.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: #f6ab2f;
}
.Toastify__toast {
    border-radius: 7px;
    font-weight: 500;
}

.Toastify__toast--default {
    font-weight: 500;
    color: var(--text-color) !important;
    /* color: var(--text-color); */
}
.Toastify__spinner {
    border-right-color: var(--primary-color) !important;
}


  
.custom_toasty_loading {
    /* background: linear-gradient(105deg, rgba(0,0,0,0), rgba(0,0,0,0), #61FF17, var(--primary-color), rgba(0,0,0,0)); */
    background: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0), rgba(97, 255, 23, 0.8), var(--primary-color), rgba(0,0,0,0));
    background-size: 200% 200%;
    width: 100%;
    min-height: 64px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding-left: 40px;
    animation: gradient_animation 1s ease-in-out infinite;
    transition: .2s;
}
.custom_toasty_loading:hover {
    background: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0), rgba(97, 255, 23, 0.0), rgba(97, 255, 23, 0.5));
}


@keyframes gradient_animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 7% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}
