.file-container {
  display: flex;
  width: 100%;
  height: 55px;
  align-items: flex-start;
  flex-direction: column;
}

.name-file {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  transition: 0.2s;
  animation: slideDown 0.5s ease-in-out forwards;
  opacity: 0;
}

.name-file:hover {
  background-color: #e2e2e2;
  transition: 0.5s;
}

.file-anexo {
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.file-anexo p {
  font-size: 12px;
  margin-top: 11px;
  margin-left: 4px;
}

.btn-trash {
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: 0.5s;
}

.loading-spinner {
  margin-left: 5px;
  border: 2px solid #c5c5c5;
  border-top: 2px solid #2be034;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
