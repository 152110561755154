@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
    --primary-color: #5DA57D;
    --primary-color-light: #c9ffd5;
    --text-color: #2D3436;
    --text-color-light: #7B7B7B;
    --border-color: #D9D9D9;
    --danger-color: #ff4d4f;

    --disabled-color: #f5f5f5;
    --disabled-text-color: #b8b8b8;

    --hover-color: #fafafa;
}