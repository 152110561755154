@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.file-container {
  display: flex;
  width: 100%;
  height: 55px;
  align-items: flex-start;
  flex-direction: column;
}

.name-file {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  transition: 0.2s;
  -webkit-animation: slideDown 0.5s ease-in-out forwards;
          animation: slideDown 0.5s ease-in-out forwards;
  opacity: 0;
}

.name-file:hover {
  background-color: #e2e2e2;
  transition: 0.5s;
}

.file-anexo {
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.file-anexo p {
  font-size: 12px;
  margin-top: 11px;
  margin-left: 4px;
}

.btn-trash {
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: 0.5s;
}

.loading-spinner {
  margin-left: 5px;
  border: 2px solid #c5c5c5;
  border-top: 2px solid #2be034;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes slideDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}




:root {
    --primary-color: #5DA57D;
    --primary-color-light: #c9ffd5;
    --text-color: #2D3436;
    --text-color-light: #7B7B7B;
    --border-color: #D9D9D9;
    --danger-color: #ff4d4f;

    --disabled-color: #f5f5f5;
    --disabled-text-color: #b8b8b8;

    --hover-color: #fafafa;
}
/* .Toastify__toast-theme--colored.Toastify__toast--success {
    background: #54c320;
} */
.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: #f6ab2f;
}
.Toastify__toast {
    border-radius: 7px;
    font-weight: 500;
}

.Toastify__toast--default {
    font-weight: 500;
    color: var(--text-color) !important;
    /* color: var(--text-color); */
}
.Toastify__spinner {
    border-right-color: var(--primary-color) !important;
}


  
.custom_toasty_loading {
    /* background: linear-gradient(105deg, rgba(0,0,0,0), rgba(0,0,0,0), #61FF17, var(--primary-color), rgba(0,0,0,0)); */
    background: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0), rgba(97, 255, 23, 0.8), var(--primary-color), rgba(0,0,0,0));
    background-size: 200% 200%;
    width: 100%;
    min-height: 64px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding-left: 40px;
    -webkit-animation: gradient_animation 1s ease-in-out infinite;
            animation: gradient_animation 1s ease-in-out infinite;
    transition: .2s;
}
.custom_toasty_loading:hover {
    background: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0), rgba(97, 255, 23, 0.0), rgba(97, 255, 23, 0.5));
}


@-webkit-keyframes gradient_animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 7% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}


@keyframes gradient_animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 7% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}

